




































































































































































































































































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";

import { SessionDataObject, SessionSpeakerObject } from "@/types/interfaces";
import EditSession from "@/components/admin/sessions/EditSession.vue";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";

const sessionStore = getModule(sessionVuexModule);


export default Vue.extend ({
    data() {
        return {

            isLoading : true,
            isSavingFavorite : false,
            favoriteError : false,
            showingBios : false,
            isSessionActive : false,
            isSessionOver : false,
            sessionLabelInterval : 0,
            timeLabel : "",
            isModalOpen : false,
        
            isSubmitting : false,
            submitSuccess : false,
            errorMessage : "",
            submitError : false,
        
            sessionReactive: {} as SessionDataObject,
        
            // if the Generic color needs to be change used this state
            // the value will be coming from changeDefaultHeaderColor in the config file
            overideGenericStyle : {
                color: ""
            }
        }
    },
    components: {
        EditSession
    },
    props: {
        session: {
             type: Object as () => SessionDataObject,
             default:{},
             required: true
        },
        showDateTime: {
            type: Boolean
        },
        isOndemandData: {
            type: Boolean
        },
        accessor: {
            type: String,
            default: ""
        }
    },

    // @Prop({ default: {}, required: true })
    // session!: SessionDataObject;

    // @Prop(Boolean)
    // showDateTime?: boolean;

    // @Prop(Boolean)
    // isOndemandData?: boolean;

    // @Prop({ default: "" })
    // accessor!: string;

    /**
     * computed
     */
    computed: {

        sessionToRender(): any {
            return this.sessionReactive.sessionId
                ? this.sessionReactive
                : this.session;
        },
    
        sessionId(): string | undefined{
            const id = this.session.sessionId;
            return id;
        },
    
        isVideoSession(): boolean {
            return Boolean(!this.session.speakeasyType && !this.session.url);
        },
    
        pageConfig() {
            return this.$store.getters.getPageOptions("sessions");
        },
    
        speakerFields(): Record<string, Record<string, string>> {
            return this.pageConfig && this.pageConfig.speakerFields
                ? this.pageConfig.speakerFields
                : ["title", "companyName"];
        },
    
        evalOptions(): Record<string, string> {
            return this.$store.getters.getPageOptions("sessionEvals");
        },
    
        evalsEnabled(): boolean {
            return !!this.evalOptions.enabled === true;
        },
    
        thisEvalDisabled(): boolean {
            return this.sessionToRender.disableSessionEval === true;
        },
    
        evalMinutes(): string {
            return this.sessionToRender.minutesBeforeSessionEval
                ? `${this.sessionToRender.minutesBeforeSessionEval}`
                : "Site Default";
        }
    },
    methods: {

        fetchData(): any {
            return sessionStore
                .getSession(this.sessionId as string)
                .then((res: any) => {
                    console.log(res);
                    return (this.sessionReactive = res);
                })
                .catch((error) => {
                    return error;
                });
        },
        checkGenericStyle() {
            this.overideGenericStyle.color = this.pageConfig.changeDefaultHeaderColor;
        },
    
        showModal() {
            this.isModalOpen = true;
        },
    
        closeModal() {
            this.isModalOpen = false;
        },
    
        handleSubmitSessionUpdate() {
            this.isLoading = true;
            eventHub.$emit("session-update-started");
            sessionStore
                .editSession()
                .then(() => {
                    eventHub.$emit("session-update-success");
                    this.fetchData();
                    this.submitSuccess = true;
                })
                .catch(() => {
                    eventHub.$emit("session-update-error");
                })
                .finally(() => {
                    eventHub.$emit("session-update-done");
                    this.isLoading = false;
                });
        },
    
        formattedSpeakerText(speaker: SessionSpeakerObject) {
            const fields = this.speakerFields as  any;
    
            let data = "";
    
            const commaFields = [] as Array<string>;
    
            fields.forEach((field: string) => {
                const key = field as keyof SessionSpeakerObject;
    
                if (speaker[key]) {
                    commaFields.push(`${speaker[key]}`);
                }
            });
    
            if (commaFields.length) {
                data += ` &ndash; ${commaFields.join(", ")}`;
            }
    
            return data;
        }
    },

    created() {
        this.checkGenericStyle();
    }
})
