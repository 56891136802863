













































































































































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import debounce from "lodash/debounce";
import TextEditor from "@/components/shared/TextEditor.vue";

import {
    SessionDataObject,
    AttendeeSearchResult,
    SessionSpeakerObject,
    SessionTags,
    AttendeeDataObject
} from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";

import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
const attendeeSearchStore = getModule(attendeeSearchVuexModule);

const sessionStore = getModule(sessionVuexModule);

interface SessionData {
    sessionId: "";
    title: string;
    description: string;
    speakers: Array<SessionSpeakerObject>;
    tags?: Array<SessionTags>;
    videoUrl: string;
    videoUrlOnDemand: string;
    displayTopic?: string;
    showOn?: {
        exhibitorList?: boolean;
    };
    hideFromSchedule?: boolean;
    networkingRoomId?: number;
    breakoutRoomSize?: string | number;
    networkingTableSize?: number;
    companyId?: string;
    hostId?: string;
    ordinal?: number;
    virtual?: boolean;
    inPerson?: boolean;
    location?: string;
    minutesBeforeSessionEval?: number;
    disableSessionEval?: boolean;
    speakerLink?: string;
}

export default Vue.extend({
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    // @Prop(String)
    // id!: string;
    data() {
        return {
            isLoading: true,
            isOpen: false,
            isLoadingAttendees: false,
            attendeeData: [],
            newTag: false,

            // model for new tag
            newTagToAdd: {
                content: "",
                color: "",
                tooltip: ""
            } as SessionTags,

            searchSelection: null as AttendeeSearchResult | null,

            updatePayload: {
                sessionId: "",
                title: "",
                description: "",
                speakers: [],
                tags: [],
                videoUrl: "",
                videoUrlOnDemand: "",
                displayTopic: "",
                showOn: {
                    exhibitorList: false
                },
                hideFromSchedule: false,
                networkingRoomId: 0,
                breakoutRoomSize: "",
                networkingTableSize: undefined,
                companyId: "",
                ordinal: undefined,
                virtual: false,
                inPerson: false,
                location: "",
                minutesBeforeSessionEval: undefined,
                disableSessionEval: false,
                speakerLink: ""
            } as SessionDataObject | SessionData,

            resetPayload: {
                sessionId: "",
                title: "",
                description: "",
                speakers: [],
                tags: [],
                videoUrl: "",
                videoUrlOnDemand: "",
                displayTopic: "",
                showOn: {
                    exhibitorList: false
                },
                hideFromSchedule: false,
                networkingRoomId: 0,
                breakoutRoomSize: "",
                networkingTableSize: undefined,
                companyId: "",
                ordinal: undefined,
                virtual: false,
                inPerson: false,
                location: "",
                minutesBeforeSessionEval: undefined,
                disableSessionEval: false,
                speakerLink: ""
            } as SessionDataObject | SessionData,

            unregisteredSpeaker: null as SessionSpeakerObject | null,

            addToCategory: "",
            submitSuccess: false,
            submitError: false
        };
    },
    components: {
        Spinners,
        TextEditor
    },
    computed: {
        attendeeSearchResults(): Array<AttendeeSearchResult> {
            return attendeeSearchStore.attendeeSearchResults;
        },

        emptySpeakerObject(): SessionSpeakerObject {
            const fields = this.speakerFields;

            const blank = {
                title: "",
                biography: "",
                country: "",
                companyName: "",
                city: "",
                state: ""
            } as SessionSpeakerObject;

            const deletedKeys = Object.keys(blank).filter(
                (key) => !fields.includes(key)
            );

            blank.name = "";
            blank.speakerRole = "";

            deletedKeys.forEach((key) => {
                const itemKey = key as keyof SessionSpeakerObject;
                if (itemKey in blank) {
                    delete blank[itemKey];
                }
            });

            return blank;
        },

        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("sessions");
        },

        speakerFields(): Array<string> {
            return this.pageConfig && this.pageConfig.speakerFields
                ? this.pageConfig.speakerFields
                : ["title", "companyName"];
        },

        attendeeFieldLabels(): any {
            return this.$store.getters.getAttendeeLabels;
        },

        /**
         * Don't let the user submit while the "unregistered speaker" box is open, to prevent
         *   submitting thinking you've added it when you haven't.
         */
        canSubmit(): boolean {
            return !this.isOpen;
        },

        evalOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("sessionEvals");
        },

        evalsEnabled(): boolean {
            return this.evalOptions.enabled === true;
        }
    },
    watch: {
        // @Watch("searchSelection")
        searchSelection() {
            const newSpeaker = { ...this.emptySpeakerObject };

            if (this.searchSelection) {
                const selectionObj = (this
                    .searchSelection as unknown) as SessionSpeakerObject;

                Object.keys(newSpeaker).forEach((key) => {
                    const itemKey = key as keyof SessionSpeakerObject;
                    if (itemKey != "name") {
                        newSpeaker[itemKey] = selectionObj[itemKey]
                            ? `${selectionObj[itemKey]}`
                            : "";
                    }
                });

                newSpeaker.id = this.searchSelection?.attendeeId
                    ? this.searchSelection.attendeeId
                    : "";
                newSpeaker.name = `${this.searchSelection.firstName} ${this.searchSelection.lastName}`;
                newSpeaker.speakerRole = "";
            }

            this.updatePayload.speakers.push(newSpeaker);

            // this.updatePayload.speakers
        }
    },

    created() {
        this.initializeSession();
        this.resetUnregisteredSpeaker();

        eventHub.$on("session-update-started", this.handleUpdateStarted);
        eventHub.$on("session-update-success", this.handleUpdateSuccess);
        eventHub.$on("session-update-error", this.handleUpdateError);
        eventHub.$on("session-update-done", this.handleUpdateDone);
    },

    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off("session-update-started", this.handleUpdateStarted);
        eventHub.$off("session-update-success", this.handleUpdateSuccess);
        eventHub.$off("session-update-error", this.handleUpdateError);
        eventHub.$off("session-update-done", this.handleUpdateDone);
    },
    methods: {
        initializeSession() {
            const SessionsPromises = [this.fetchSession()];

            this.isLoading = true;

            Promise.resolve(SessionsPromises);
        },

        setUpdatePayload() {
            const payload: any = this.updatePayload;
            sessionStore.setSessionPayload(payload);
        },

        handleSubmit() {
            this.setUpdatePayload();
            this.$emit("submit");
        },

        handleUpdateStarted() {
            this.isLoading = true;
        },

        handleUpdateSuccess() {
            this.submitSuccess = true;
        },

        handleUpdateError() {
            this.submitError = true;
        },

        handleUpdateDone() {
            this.isLoading = false;
        },

        fetchSession() {
            console.log("fetch session");

            return new Promise<void>((resolve, reject) => {
                sessionStore
                    .getSingleSession({
                        sessionId: this.id,
                        isDemoData: false
                    })
                    .then((response: any) => {
                        console.log("fetched session");
                        console.log(response);

                        this.updatePayload.sessionId = response.sessionId;
                        this.updatePayload.title = response.title;
                        this.updatePayload.displayTopic = response.displayTopic;
                        this.updatePayload.companyId = response.companyId;
                        this.updatePayload.hostId = response.hostId;
                        this.updatePayload.ordinal = response.ordinal;
                        this.updatePayload.breakoutRoomSize = +response.breakoutRoomSize; //cast to number to make numberinput work
                        this.updatePayload.networkingTableSize =
                            response.networkingTableSize;
                        response.networkingRoomId;
                        this.updatePayload.showOn =
                            response.showOn ||
                            Object.assign({
                                exhibitorList: false
                            });
                        this.updatePayload.hideFromSchedule =
                            response.hideFromSchedule;
                        this.updatePayload.description = response.description;
                        this.updatePayload.speakers = response.speakers
                            ? [...response.speakers]
                            : [];
                        this.updatePayload.videoUrl = response.videoUrl;
                        this.updatePayload.videoUrlOnDemand =
                            response.videoUrlOnDemand;
                        this.updatePayload.virtual = response.virtual;
                        this.updatePayload.inPerson = response.inPerson;
                        this.updatePayload.location = response.location;
                        this.updatePayload.disableSessionEval =
                            response.disableSessionEval;
                        this.updatePayload.minutesBeforeSessionEval =
                            response.minutesBeforeSessionEval;
                        this.updatePayload.speakerLink = response.speakerLink;

                        this.isLoading = false;
                        return resolve();
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            });
        },

        handleCancel() {
            this.resetForm();
            this.$emit("cancel");
        },
        resetForm() {
            this.updatePayload = JSON.parse(JSON.stringify(this.resetPayload));
        },

        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSuccess = false;
            this.submitError = false;
        },

        resetFlags() {
            this.isLoadingAttendees = false;
        },
        getAsyncData(keyword: string) {
            this.isLoadingAttendees = true;
            this.fetchAttendees(keyword);
        },

        fetchAttendees: debounce(function(this: any, keyword: string) {
            const searchInput = {
                allFields: keyword,
                context: "admin"
            };
            attendeeSearchStore
                .searchAttendees(searchInput)
                .then((res: any) => {
                    this.attendeeData = [];
                    res.forEach((item: any) => {
                        this.attendeeData.push(item);
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.resetFlags();
                });
        }, 500),

        removeSpeaker(speaker: string) {
            const updateSpeaker = this.updatePayload.speakers.filter(
                (item: SessionSpeakerObject) => {
                    return item.name != speaker;
                }
            );
            this.updatePayload.speakers = [];
            this.updatePayload.speakers = [...updateSpeaker];
        },

        addNewTag(objTag: SessionTags) {
            const newObjTag = {
                content: objTag.content,
                color: objTag.color,
                tooltip: objTag.tooltip
            };
            // this.updatePayload.tags?.push(newObjTag);
            this.clearAddTagFields();
            this.newTag = false;
        },
        clearAddTagFields() {
            this.newTagToAdd.content = "";
            this.newTagToAdd.color = "";
            this.newTagToAdd.tooltip = "";
        },

        addUnregisteredSpeaker() {
            const newSpeaker = {
                ...this.unregisteredSpeaker
            } as SessionSpeakerObject;
            this.updatePayload.speakers.push(newSpeaker);
            this.isOpen = false;
            this.clearUnregisteredSpeakerField();
        },

        resetUnregisteredSpeaker() {
            this.unregisteredSpeaker = { ...this.emptySpeakerObject };
        },

        clearUnregisteredSpeakerField() {
            this.resetUnregisteredSpeaker();
            this.isOpen = false;
        },

        changeDescription(desc: any) {
            this.updatePayload.description = desc;
        },

        fieldLabel(field: string) {
            return this.attendeeFieldLabels[field]
                ? this.attendeeFieldLabels[field]
                : field;
        }
    }
});
