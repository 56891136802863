<template>
    <section>
        <div class="table-schedule-body">
            <!-- 
                `destroy-on-hide` - Keep this in place.
                This is for improved performance. 
                Each schedule item runs setinterval code, 
                with `destroy-on-hide` we only run nessecary interval code / freeing up JS thread.
            -->

            <b-tabs
                class="mg-directory-tabs mg-directory-tabs--live-tab-1 mg-directory-tabs--transparent-tab-2 mg-tabs-no-margin"
                ref="Tabs"
                position="is-centered"
                type="is-boxed"
                destroy-on-hide
            >
                <div
                    :class="
                        cssVariables['--session-live-content-bg-color']
                            ? cssVariables['--session-live-content-bg-color']
                            : 'bg-gradient'
                    "
                >
                    <component :is="'AdminSessionSchedule'" />
                </div>
            </b-tabs>
        </div>
    </section>
</template>

<script>
import AdminSessionSchedule from "@/components/admin/sessions/AdminSessionSchedule.vue";

export default {
    name: "AdminSessionsView",
    components: {
        AdminSessionSchedule
    },

    data() {
        return {
            allSessions: []
        };
    },

    computed: {
        cssVariables() {
            return this.$store.getters.cssVariables;
        },

        liveContentBgColor() {
            return this.cssVariables["--session-live-content-bg-color"];
        },

        getConfigurableLabel() {
            return this.$store.getters.sessionLabel;
        },

        getLayoutOptions() {
            return this.$store.getters.layoutOptions;
        },

        liveComponentToUse() {
            return this.$store.getters.getPageOptions("sessions").section.live;
        },
        pageheader() {
            return this.$store.getters.getPageOptions("sessions").section
                .pageHeader;
        }
    },
    mounted() {
        if (
            this.$route.query &&
            ("onDemandOpen" in this.$route.query ||
                "onDemandFilter" in this.$route.query)
        ) {
            // this.activeTab = 1;
        }
        // Work around - a11y fix. Add href so screen readers can pick up tabs.
        // need better solution to adding these attributes.
        setTimeout(function() {
            const tabLinks = document.querySelectorAll(".tabs a");
            [...tabLinks].forEach((item) => {
                if (!item.getAttribute("href")) {
                    item.href = "#";
                    item.setAttribute(
                        "aria-label",
                        `toggle pannel for ${item.innerText} sessions`
                    );
                }
            });
        }, 1000);
    },

    methods: {
        getVideoId(type = null, track = null, index = null) {
            if (!this.SessionInfo || !type || !track || null === index)
                return "";

            if (this.SessionInfo[type]) {
                return this.SessionInfo[type][track].sessions[index]["Vimeo"][
                    "video_id"
                ];
            }

            return "";
        },

        showCategory(cat) {
            this.activeCategory = cat;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/views/session";
</style>
